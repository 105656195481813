import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { Gender } from './gender-type.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class GenderTypeService
{
	private httpClient = inject(HttpClient);


	public get(): Observable<Gender[]>
	{
		const url = `${ environment.baseUrl  }/general/gender-types`;

		return this.httpClient.get<Gender[]>(url);
	}
}
