import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientContactCreateRequestModel } from './contact-create-request.model';
import { ClientContactResponseModel } from './contact-response.model';
import { ClientContactUpdateRequestModel } from './contact-update-request.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class ClientContactService
{
	private http = inject(HttpClient);


	public get(contactId: string) : Observable<ClientContactResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/contact/${ contactId }`;

		return this.http.get<ClientContactResponseModel>(url);
	}

	public getByClientId(clientId: string) : Observable<ClientContactResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/client/contacts/client/${ clientId }`;

		return this.http.get<ClientContactResponseModel[]>(url);
	}

	public create(clientContact: ClientContactCreateRequestModel): Observable<ClientContactResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/contact/client/${ clientContact.clientId }`;

		return this.http.post<ClientContactResponseModel>(url, clientContact);
	}

	public update(clientContact: ClientContactUpdateRequestModel): Observable<ClientContactResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/contact/${ clientContact.contactId }`;

		return this.http.put<ClientContactResponseModel>(url, clientContact);
	}

	public delete(contactId: string): Observable<ClientContactResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/contact/${ contactId }`;

		return this.http.delete<ClientContactResponseModel>(url);
	}
}
